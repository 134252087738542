@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,600,700);

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #9099a0;
}

::-webkit-scrollbar-thumb {
  background: #00d7bd;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00bfda;
  border-radius: 5px;
}
